.page {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  .page-header {
    flex-shrink: 0;
  }
  .page-content {
    flex-grow: 1;
    position: relative;
    overflow: auto;
  }
  .page-loaded-content {
    position: absolute;
  }
}
.dummy-content {
  position: absolute;
  &.loaded {
    animation: fadeOut 0.5s linear forwards;
  }
}
