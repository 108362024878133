.form,
.form-group {
  width: 100%;
  max-width: 72rem;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  position: relative;
  align-items: end;
  &.no-gap {
    grid-gap: 0rem;
  }
  &.no-row-gap {
    grid-gap: 0rem;
    column-gap: 2rem;
  }
  .options {
    position: absolute;
    right: 0;
  }
  &.disabled {
    .formfield {
      pointer-events: none;
      .label {
        color: $color-grey-800;
        pointer-events: none;
      }
      mat-form-field .mat-form-field-flex,
      .editor,
      .btn {
        color: $color-grey-900;
        border-color: $color-grey-50;
        background-color: $color-grey-50;
        pointer-events: none;
        cursor: default;
        input.mat-input-element,
        *[matSuffix] {
          color: $color-grey-800;
        }

        .e-toolbar-items {
          background-color: $color-grey-50;
        }
      }
      .mat-slide-toggle,
      .mat-radio-button {
        opacity: 0.4;
        pointer-events: none;
      }
      .editor [contenteditable] {
        background-color: $color-grey-50;
      }
    }
  }
}
$levels: (0, 1, 2, 3, 4, 5);
@each $key in $levels {
  .form-group-#{$key} {
    padding-left: $key * 2rem;
  }
}
.formfield {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .mat-form-field-flex {
    background-color: white;
  }
  > mat-hint {
    font-size: 1.1rem;
    color: $color-grey-800;
    margin-top: 4px;
  }
  .label {
    transition: all 0.3s ease-out;
    color: $color-grey-900;
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 0.3rem;
    a {
      .field-clear {
        color: $color-grey-800;
        display: none;
      }
      &:hover,
      &.active,
      &:active {
        .field-clear {
          color: $color-error;
        }
      }
    }
    &.red {
      color: $color-error !important;
    }
  }
  &:focus-within {
    .field-clear {
      display: inherit !important;
    }
  }
  &.required {
    .label,
    .file-upload .card-dashed {
      color: $color-grey-900;
    }
    .file-upload .card-dashed.error {
      border-color: $color-error;
    }
    &:not(.disabled) {
      lib-data-formats .label > span:after {
        content: "*";
        color: $color-error;
      }
    }
    .mat-checkbox-frame {
      border-color: $color-grey-900 !important;
    }
  }
  &:not(.required) {
    .mat-checkbox-frame {
      border-color: $color-grey-800 !important;
    }
  }
  &.prefilled {
    mat-form-field .mat-form-field-flex,
    .editor {
      border-color: $color-warning;
    }
  }
  .clear-search {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 1.2rem;
    height: 1.2rem;
    background-color: $color-grey-600;
    border-radius: 9999px;
    color: white;
    &:hover,
    &:active,
    &.active {
      background-color: $color-grey-200;
    }
    mat-icon {
      font-size: inherit;
      width: auto !important;
    }
  }
  &:hover,
  &:active,
  &.active {
    mat-form-field .mat-form-field-flex,
    .editor,
    .file-upload .card-dashed {
      border-color: $color-primary-600;
    }
    .field-clear {
      display: inherit !important;
    }
  }
  &.no-highlight {
    mat-form-field .mat-form-field-flex,
    .editor,
    .file-upload .card-dashed {
      border-color: $color-grey-200;
    }
  }
  &.readonly,
  &.disabled {
    .label {
      color: $color-grey-800;
    }
    mat-form-field .mat-form-field-flex,
    .editor {
      color: $color-grey-900;
      border-color: $color-grey-50;
      background-color: $color-grey-50;
      pointer-events: none;
      cursor: default;
      input.mat-input-element,
      *[matSuffix] {
        color: $color-grey-800;
      }
    }
    .mat-slide-toggle {
      opacity: 0.4;
      pointer-events: none;
    }
    .editor [contenteditable] {
      background-color: $color-grey-50;
    }
  }
  &.disabled {
    pointer-events: none;
  }
  &.item-dummies-container {
    position: relative;
    justify-content: start;
  }
}

.form,
.form-group {
  > * {
    grid-column-start: auto;
    &.newline {
      grid-column-start: 1;
    }
    &.medium,
    &.medium_small {
      grid-column-end: span 2;
    }
    &.medium-large {
      grid-column-end: span 3;
    }
    &.large,
    &.large_small,
    &.large_medium {
      grid-column-end: span 4;
    }
  }
}

.formfield {
  &.medium_small,
  &.large_medium {
    .mat-form-field,
    .choice-radio-field,
    .choice-checkbox-field {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
  &.large_small {
    .mat-form-field,
    .choice-radio-field,
    .choice-checkbox-field {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

.formfield .mat-form-field .mat-select {
  .mat-select-trigger {
    height: auto;
    .mat-select-value-text {
      white-space: normal;
    }
  }
}

.choice-formfield {
  mat-hint.mat-hint {
    font-size: 1.1rem;
  }
  .formfield mat-form-field .mat-form-field-flex .mat-form-field-infix .mat-chip-list-wrapper mat-chip.mat-chip {
    height: auto;

    &.existing-option {
      background-color: black;
    }

    span {
      white-space: normal;
      line-height: initial;
    }
  }
}

.formfield mat-slide-toggle {
  font-size: 1.3rem;
  &.mat-checked {
    .mat-slide-toggle-bar {
      background-color: rgba($color-success, 0.5);
    }
    .mat-slide-toggle-thumb {
      background-color: $color-success;
    }
    .mat-ripple-element {
      background-color: $color-success;
    }
  }
}
.formfield {
  &.no-padding {
    .mat-form-field,
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  .superscript-hint {
    .mat-form-field-wrapper {
      padding-bottom: 0;
      display: flex;
      flex-direction: column-reverse;
      .mat-form-field-subscript-wrapper {
        position: relative;
        padding: 0;
        margin: 0;
        .mat-error,
        .mat-hint {
          padding-bottom: 0.5rem;
        }
      }
    }
  }
  .rich-text-formfield {
    display: flex;
    flex-direction: column-reverse;
    .mat-hint,
    .mat-error {
      font-size: 1.1rem;
      display: flex;
      padding-bottom: 0.5rem;
    }
  }
}

.formfield.formfield-masked {
  .mat-form-field {
    .mat-form-field-flex {
      background-color: $color-grey-50;
      border-color: $color-grey-50;
    }
  }
}
// check box
.formfield.prefilled {
  .mat-checkbox-checked {
    .mat-checkbox-background {
      background-color: $color-warning !important;
    }
    .mat-ripple-element {
      background-color: $color-warning !important;
    }
  }
  .mat-checkbox-frame {
    border-color: $color-warning !important;
  }
}

.formfield {
  &.choice-formfield {
    .error {
      .mat-checkbox-frame {
        border-color: $color-error !important;
      }
    }
  }
  mat-checkbox {
    &.cdk-keyboard-focused {
      &.mat-checkbox-checked {
        .mat-checkbox-background {
          background-color: $color-primary-600 !important;
        }
      }
      .mat-checkbox-frame {
        border-color: $color-primary-600 !important;
      }
      .mat-ripple-element {
        display: none;
      }
    }
  }
}

//radio buttons
.formfield {
  .mat-radio-label {
    width: auto;
    white-space: normal;
  }
  .choice-radio {
    &:focus {
      .label {
        color: $color-primary-600;
      }
    }
  }
}

.formfield mat-form-field {
  width: 100%;
  padding-bottom: 0.5rem;
  .mat-form-field-underline {
    display: none;
  }
  &.mat-focused .mat-form-field-flex {
    border-color: $color-primary-600 !important;
  }
  .mat-form-field-flex {
    display: flex;
    align-items: center;
    min-height: 3.5rem;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    border: 1.5px solid $color-grey-200;
    transition: all 0.3s ease-out;
    border-radius: 3px;
    .mat-form-field-infix {
      width: 100%;
      border-top-width: 0;
      padding-top: 0;
      padding-bottom: 0;
      font-size: 1.3rem;
      textarea:not(.cdk-textarea-autosize) {
        background-image: url(/assets/icons/arrows/arrow-corner.svg);
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 1rem 1rem;
      }
      .mat-chip-list-wrapper {
        mat-chip.mat-chip {
          border-radius: 3px;
          height: 2rem;
          min-height: auto;
          font-size: 1.1rem;
          padding: 0 0.5rem;
          span {
            max-width: 30rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          mat-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: white;
            opacity: 1;
            width: 1rem;
            height: 1rem;
            color: $color-primary-900;
            svg {
              width: 0.6rem;
              height: 0.6rem;
            }
            &:hover,
            &:active {
              color: $color-primary-600;
            }
          }
          background-color: $color-primary-900;
          color: white;
          &:hover,
          &:active,
          &.active {
            opacity: 1;
            color: white;
            mat-icon {
              background-color: white;
            }
          }
          &::after {
            background-color: transparent;
          }
          &.success {
            background-color: $color-success;
            mat-icon {
              color: $color-success;
            }
          }
          &.error {
            background-color: $color-error;
            mat-icon {
              color: $color-error;
            }
          }
          &.disabled {
            color: $color-grey-900;
            background-color: $color-grey-200;
            .mat-icon {
              color: $color-grey-900;
            }
          }

          &.stroked {
            border: 1.5px solid $color-primary-900;
            color: $color-primary-900;
            background-color: transparent;

            mat-icon {
              background-color: $color-primary-900;
              color: white;
              &:hover,
              &:active {
                color: $color-primary-600;
              }
            }
          }
        }
      }
    }
    *[matSuffix] {
      flex-shrink: 0;
      padding-right: 1rem;
    }
    mat-error {
      display: none !important;
    }
    mat-form-field mat-error:first-child {
      display: block !important;
    }
  }
  .mat-formfield-subscript-wrapper {
    margin-top: 6px;
  }
  mat-hint.mat-hint {
    font-size: 1.1rem;
    color: $color-grey-800;
    padding-bottom: 0.5rem;
  }
  &.ng-touched.ng-invalid {
    .mat-form-field-flex {
      border-color: $color-error !important;
    }
  }
}

.formfield.small {
  .maximum-field-width .mat-form-field-flex {
    padding: spacing(0.4) spacing(0.4) spacing(0.4) spacing(0.8);
    *[matSuffix] {
      padding-right: spacing(0.4);
    }
  }
}

// placeholder label
.mat-form-field-label {
  margin-top: -2px !important;
  line-height: 1.2 !important;
  top: unset !important;
  span {
    color: $color-grey-800;
  }
}
.rte-placeholder {
  padding: 1.4rem 1.6rem 0 1.6rem !important;
}

// file type
.formfield {
  .label.uploading {
    color: $color-primary-600;
  }
  .uploading {
    display: none;
  }
  .upload:focus {
    border: 1.5px solid $color-primary-600;
  }
  &.file-uploading {
    .upload {
      display: none;
    }
    .uploading {
      display: flex;
      &.card {
        background-color: $color-grey-50;
      }
    }
  }
}

// mat select
.mat-select-panel.form-select-panel {
  max-height: none;
  margin-top: 1px;
  .select-search {
    margin-left: 1rem;
    padding-left: 1rem;
    min-height: 2.5rem;
    min-width: 20rem;
    height: 4rem;
    background-color: transparent;
    input {
      margin: 0 0.5rem;
      border: none;
      background-color: transparent;
      outline: none;
      width: 100%;
    }
    .clear-search {
      padding: 0 2px;
      background-color: $color-grey-600;
      border-radius: 50%;
      color: white;
      margin-right: 1rem;
      &:hover,
      &:active,
      &.active {
        background-color: $color-grey-200;
      }
    }
  }
  .options-container {
    max-height: 30rem;
    overflow: auto;
  }
  mat-option {
    &.mat-selected {
      span {
        border-radius: 3px;
        background-color: $color-primary-900;
        color: white;
        margin-left: -16px;
        padding-left: 16px;
        margin-right: -16px;
        padding-right: 16px;
        margin-top: -11.7px;
        margin-bottom: -11.7px;
        padding-top: 11.7px;
        padding-bottom: 11.7px;
      }
    }
  }
}
// multi-select
.multi-select {
  outline: none;
  &:focus {
    .multi-select-focus {
      &.mat-checkbox-checked {
        .mat-checkbox-background {
          background-color: $color-primary-600 !important;
        }
      }
      .mat-checkbox-frame {
        border-color: $color-primary-600 !important;
      }
      .mat-ripple-element {
        display: none;
      }
    }
  }
}

// rich text
.formfield .editor {
  border: 1.5px solid $color-grey-200;
  transition: all 0.3s ease-out;
  border-radius: 3px;
  &.e-rte-full-screen {
    .e-rte-content {
      margin-top: 30px !important;
      .e-content {
        min-height: auto;
        max-height: 100% !important;
      }
    }
  }
  &.e-richtexteditor {
    .e-rte-content {
      font-size: 1.3rem;
      font-family: "Poppins";
      border: 0;
      .e-content {
        min-height: auto;
        max-height: 20rem;
      }
    }
    .e-rte-toolbar {
      border: 0;
    }
    .e-rte-toolbar {
      background-color: transparent;
      border-bottom: 1px solid $color-grey-200;
    }
    .e-toolbar-items {
      background-color: white;
    }
    .e-tbar-btn {
      background-color: transparent;
      &:hover,
      &:active {
        background-color: $color-grey-200;
      }
    }
  }
  &.error {
    border-color: $color-error;
  }
}

.form-submit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  a {
    margin-right: 2rem;
  }
}

.mat-checkbox-label {
  white-space: pre-line;
}

// Remove link colors for metric structure editor
.form.metric-editor .formfield {
  &:hover,
  &:active,
  &.active {
    .label {
      color: $color-grey-800;
    }
    mat-form-field .mat-form-field-flex,
    .editor {
      border-color: $color-grey-200;
    }
    &.required {
      .label {
        color: $color-grey-900;
      }
      mat-form-field .mat-form-field-flex,
      .editor {
        border-color: $color-grey-800;
      }
    }
  }
}

.mat-autocomplete-panel {
  .mat-option {
    white-space: normal;
    line-height: initial;
    .mat-option-text {
      font-size: 1.2rem;
      font-weight: 500;
      color: $color-primary-900;
    }
  }
}

.formfield mat-form-field {
  &.select-menu {
    .mat-form-field-wrapper {
      padding: 0;
    }
  }
}

// Overlays
.overlay {
  position: absolute;
  top: -1rem;
  right: -1rem;
  bottom: -1rem;
  left: -1rem;
  border: 1.5px solid transparent;
  transition: border-color 0.3s ease-out;
  &:hover {
    border-color: $color-primary-600;
    cursor: pointer;
    .handler,
    .actions {
      display: flex;
    }
  }
  &:active,
  &.active {
    border-color: $color-primary-600;
  }
  &.disabled {
    .actions,
    .handler {
      display: none;
    }
  }
}
.overlay .handler {
  display: none;
  position: absolute;
  cursor: move;
  align-items: center;
  background-color: rgba($color: $color-primary-600, $alpha: 0.3);
  top: -1.5px;
  left: -1.1rem;
  bottom: -1.5px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  width: 1rem;
  color: $color-primary-600;
  mat-icon {
    transform: rotate(90deg);
  }
}
.overlay .actions {
  display: none;
  position: absolute;
  height: 2rem;
  top: -2.15rem;
  right: -1.5px;
  padding: 0.4rem 0.5rem 0 0.5rem;
  background-color: $color-primary-600;
  color: white;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  a {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: 2rem;
    color: white;
    &:hover {
      color: $color-primary-600;
    }
    &::after {
      background: none;
    }
  }
}

.multiline-mat-option.mat-option {
  white-space: normal;
  line-height: normal !important;
  height: unset !important;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}

.field-form-container > .inactive {
  opacity: 60% !important;
}
