html {
  font-size: 10px;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
body {
  margin: 0;
}
*,
*:before,
*:after {
  // font-family: 'Poppins';
  box-sizing: inherit;
}
app-root {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: auto;
}

// z-index map
// ***********
// 2: content inside overlay border for metric items
// 3: benchmark first column - since overlay contents are z-index 2
// 10: dummy list on metric view panel
// 10: pdf viewer
// 10: metric editor dummy
// 10: aside
// 10: dummy collection
// 11: collection right shadow
// 12: toggle link before
// 13: toggle link after
// 14: toggle icon
// 20: action close, action save
// 100: main menu
// 1000: app loader
