@import "../../novisto/styles/styles.scss";

body,
html {
  height: 100%;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  background-color: $color-white;
}

* {
  font-family: "Poppins";
  font-size: 1.2rem;
  font-style: normal;
}
