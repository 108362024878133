.text-diff {
  ins {
    text-decoration: none !important;
    background-color: $color-ins-marker-bg-color;
    color: $color-ins-marker;
  }
  del {
    text-decoration: line-through !important;
    text-decoration-color: $color-del-marker;
    color: $color-del-marker;
  }
}
