.loading {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  &.true {
    animation: fadeOut 1s linear forwards;
  }
  .wrapper {
    position: relative;
    .n {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 2rem;
        height: 2rem;
      }
    }
  }
}
