$columns: (1, 2, 3, 4, 5, 6);
$sizes: ("xs", "sm", "md", "lg", "xl", "narrow", "wide");
.grid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: 1fr;
  .grid-custom-cols {
    grid-column-start: 1;
    @each $column in $columns {
      &.grid-custom-cols-#{$column} {
        grid-column-end: #{1 + $column};
      }
    }
  }
}
@each $column in $columns {
  .grid.grid-#{$column} {
    grid-template-columns: repeat(#{$column}, 1fr);
  }
}
@each $size in $sizes {
  @each $column in $columns {
    .#{$size} .grid.grid-#{$size}-#{$column} {
      grid-template-columns: repeat(#{$column}, 1fr);
    }
  }
}
