.table-format {
  text-align: left;
  border-collapse: collapse;
  th,
  td {
    justify-content: space-between;
    font-size: 1.3rem;
    font-weight: 400;
    border: 1px solid $color-grey-200;
    color: $color-grey-900;
    padding: 1rem 1rem 1.2rem 1rem;
    &:first-child {
      padding-left: 2rem;
      border-right: 1px solid $color-grey-800;
    }
    &:focus {
      outline: none;
      box-shadow: 0rem 0rem 0.4rem $color-primary-600;
      border-radius: 3px;
    }
  }
  th {
    border-bottom: 1px solid $color-grey-800;
  }
}

.table-wrapper {
  position: relative;
  height: 100%;
  width: 100%;
  .table-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    background-color: $color-grey-50;
  }
  .item-dummies {
    display: none;
  }
}

.table-header {
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: row;
  z-index: 2;
  > div {
    min-width: 20rem;
    width: 20rem;
    border-right: 1px solid $color-grey-200;
    border-bottom: 1px solid $color-grey-800;
    height: 6rem;
    overflow: hidden;
    background-color: white;
    div:first-of-type {
      margin-bottom: 0.5rem;
    }
    &:first-child {
      padding-left: 2rem !important;
      border-right: 1px solid $color-grey-800;
      position: sticky;
      left: 0;
    }
    &:first-of-type {
      z-index: 1;
      padding: 1.4rem;
    }
    a.card {
      padding: 1rem;
      width: 100%;
      height: 100%;
      a.card-menu {
        height: 2.5rem;
        width: 2.5rem;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        justify-content: center;
        align-items: center;
      }
      &:hover,
      &:active,
      &.active {
        a.card-menu {
          display: flex;
        }
      }
    }
  }
}

.table-list {
  display: block;
}

.table-row {
  box-sizing: border-box;
  height: 12rem;
  display: flex;
  flex-direction: row;
  * {
    line-height: 1.4rem;
  }
  > div {
    overflow: hidden;
    box-sizing: border-box;
    height: 12rem;
    width: 20rem;
    min-width: 20rem;
    border-right: 1px solid $color-grey-200;
    border-bottom: 1px solid $color-grey-200;
    &:first-child {
      border-right: 1px solid $color-grey-800;
      position: sticky;
      left: 0;
      background-color: white;
      padding: 0;
      .card {
        padding-left: 1rem;
      }
    }
    &:first-of-type {
      background-color: white;
      z-index: 3;
    }
    .icon-text {
      margin-top: 1.3rem;
    }
    a.card {
      width: 100%;
      height: 100%;
      a.card-menu {
        height: 2.5rem;
        width: 2.5rem;
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        justify-content: center;
        align-items: center;
      }
      &:hover,
      &:active,
      &.active {
        a.card-menu {
          display: flex;
        }
      }
    }
  }
}

.text-overflow {
  line-height: 1.3;
  position: relative;
  display: block;
  height: 12rem;
  overflow: hidden;
  .text-overflow-content {
    box-sizing: border-box;
    position: relative;
    display: block;
    max-height: 12rem;
  }
  .text-overflow-more {
    background-color: white;
    position: absolute;
    box-sizing: border-box;
    padding: 0.2rem 0.5rem 0.5rem 0;
    left: 0;
    right: 0;
    text-align: right;
    top: calc(21.5rem - 100%);
  }
  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.table-grid-tree {
  .table-header {
    z-index: 3;
    div {
      &:first-of-type {
        height: 4rem;
        width: 38rem;
        min-width: 38rem;
      }
      min-width: auto;
    }
    .columns {
      height: 4rem;
      display: flex;
      flex-grow: 1;
    }
  }
  .table-body {
    &.readonly {
      .more-option,
      .editable {
        display: none !important;
      }
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      width: 100%;
      height: 0.4rem;
      background-image: linear-gradient(to bottom, $color-grey-200, $color-grey-50);
    }
  }
  .card {
    border-radius: inherit;
    margin: 0;
    .more-option {
      display: none;
    }
    &:hover,
    &:active,
    &.active {
      .more-option {
        display: flex;
      }
    }
    &.card-color-medium {
      .more-option {
        color: white;
        &:hover,
        &:active {
          color: $color-primary-600;
        }
      }
    }
  }
  mat-tree {
    .sub-section {
      height: 3rem;
    }
    .root-section {
      height: 4rem;
    }
    mat-nested-tree-node:first-of-type {
      li {
        margin: 0 !important;
      }
    }
  }
  .metric-table-container {
    width: 100%;
    border-bottom: 1px solid $color-grey-800;
    .metric-table-header {
      display: flex;
      flex-direction: row;
      .header-column {
        width: 38rem;
        border-right: 1px solid $color-grey-800;
        .card {
          min-height: 1.6rem;
          border-right-color: transparent;
          height: 100%;
          &:hover,
          &.active,
          &:active {
            border: 1.5px solid $color-primary-600;
          }
        }
      }
      .columns {
        display: flex;
        flex: 1 0 0;
        border-right: 1px solid $color-grey-200;
        border-bottom: 1px solid $color-grey-200;
        word-break: break-all;
        padding: 0.5rem 2rem;
      }
    }
    .metric-table-rows {
      display: flex;
      flex-direction: column;
      .row {
        display: flex;
        flex-direction: row;
        .row-title {
          width: 38rem;
          padding: 0.5rem 2rem;
          border-bottom: 1px solid $color-grey-200;
          border-right: 1px solid #777;
        }
        .row-items {
          display: flex;
          flex: 1 0 0;
          border-right: 1px solid $color-grey-200;
          padding: 0.5rem 2rem;
          border-bottom: 1px solid $color-grey-200;
          word-break: break-all;
        }
        &:last-of-type {
          div {
            border-bottom: 0;
          }
        }
      }
    }
    ul,
    li {
      list-style-type: disc;
    }
  }
  .label {
    display: none;
  }
  .text {
    div,
    p {
      font-size: 1.1rem !important;
      margin: 0 !important;
    }
  }
}
