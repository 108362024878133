@use "@angular/material" as mat;

.env-bar {
  min-height: 2.5rem;
  background-color: $color-error;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  & + .app-wrapper {
    overflow: auto;
  }
}

.app-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.page-wrapper,
nav,
.content,
aside,
header,
footer {
  transition: all 0.3s ease-out;
}

// **************************************************
// Main layout
// **************************************************

.page-wrapper {
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: calc(100% - 54px);
}
.page-wrapper > nav {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: absolute;
  left: 0;
  width: 0;
  height: 100%;
  overflow: hidden;
  z-index: 100;
  background-color: $color-primary-900;
  &.open {
    width: 26rem;
  }
}

.content,
router-outlet + * {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
}
aside {
  display: flex;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 10;
  &.aside-right {
    right: 0;
    order: 1;
  }
}
header,
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

// Narrow screen

.narrow .page-wrapper.level-1 > nav.open {
  width: 26rem;
}

// Wide screen

.wide aside {
  position: relative;
}
.wide .page-wrapper.level-1 > nav {
  position: relative;
  width: 26rem;
  .close {
    display: none;
  }
}

// **************************************************
// Main nav
// **************************************************

.page-wrapper > nav header {
  padding: 2rem 3rem 2.5rem 3rem;
}
.page-wrapper > nav a.logo {
  color: white;
  mat-icon {
    width: 10rem !important;
    height: 2rem !important;
  }
  &:hover,
  &:active,
  &.active {
    color: $color-primary-600;
  }
}
.page-wrapper > nav a.close {
  color: white;
  &:hover,
  &:active,
  &.active {
    color: $color-primary-600;
  }
}
.page-wrapper > nav .content a {
  color: $color-white;
  &:hover,
  &:active,
  &.active {
    color: $color-primary-600;
  }
  display: flex;
  align-items: center;
  padding: 1rem 2.8rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
  mat-icon {
    display: flex;
    flex-shrink: 0;
    margin-right: 1rem;
  }
  > div {
    display: flex;
    flex-grow: 1;
  }
}
.page-wrapper > nav .profile {
  margin-top: 0.5rem;
  .mat-list-item-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    background-color: $color-success;
    border: 1.5px solid $color-success;
    font-weight: 500;
    border-radius: 999px;
    color: white;
    transition: all 0.3s ease-out;
  }
  &:hover span,
  &.active span {
    background-color: transparent;
  }
}
.page-wrapper > nav hr {
  margin: 1rem 2.8rem;
  height: 1px;
  border: none;
  background-color: $color-primary-800;
}

// **************************************************
// Toolbar
// **************************************************

.page-wrapper .toolbar {
  * {
    margin: 0;
    line-height: 1;
  }
  position: relative;
  transition: all 0.3s ease-out;
  background-color: $color-primary-900;
  color: white;
  header {
    height: 5rem;
    padding-left: 0.85rem;
    padding-right: 2rem;
  }
  header .open-menu {
    display: none;
    margin-right: 1rem;
    padding-left: 1rem;
  }
  header .parent-link {
    display: flex;
    margin-right: 1rem;
    padding-left: 1rem;
  }
  a mat-icon {
    width: 2rem;
    height: 2rem;
  }
  header .toolbar-title {
    display: flex;
    align-items: center;
    a {
      color: white;
      &:hover,
      &:active,
      &.active {
        color: $color-primary-600;
      }
    }
    .title {
      display: flex;
      align-items: center;
      position: relative;
      .title-text,
      input {
        font-size: 1.6rem;
        font-weight: 600;
      }
      .title-text {
        max-width: 30rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: 1.5px solid transparent;
        border-radius: 3px;
        padding: 0.8rem 1rem 0.7rem 1rem;
      }
      .title-icon {
        display: none;
        color: $color-primary-600;
      }
      input {
        box-sizing: border-box;
        width: 30rem;
        border: 1.5px solid $color-primary-600;
        border-radius: 3px;
        background-color: transparent;
        color: white;
        caret-color: white;
        padding: 0.1rem 1rem 0.1rem 1rem;
        outline: none;
      }
      .title-actions {
        position: absolute;
        right: 0;
        top: calc(100% + 2px);
        mat-icon {
          width: 1.2rem;
          height: 1.2rem;
        }
        display: flex;
        align-items: center;
        a {
          display: flex;
          align-items: center;
          border: 1.5px solid $color-grey-200;
          border-radius: 3px;
          background-color: white;
          color: $color-primary-900;
          font-size: 1.2rem;
          font-weight: 500;
          height: 2.5rem;
          padding: 0 0.65rem;
          z-index: 20;
          &.action-close:hover {
            border-color: $color-error;
            color: $color-error;
          }
          &.action-save {
            margin-left: 0.5rem;
            mat-icon {
              margin-right: 0.4rem;
            }
            &:hover {
              border-color: $color-success;
              mat-icon {
                color: $color-success;
              }
            }
          }
        }
      }
      &.editable {
        &:hover {
          .title-icon {
            display: flex;
          }
        }
      }
    }
    .subtitle {
      max-width: 50rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  header .toolbar-actions {
    display: flex;
    align-items: center;
    > * {
      margin-left: 2rem;
    }
    a {
      color: white;
      display: flex;
      align-items: center;
      &:hover,
      &:active,
      &.active {
        color: $color-primary-600;
      }
    }
    a.btn {
      color: white;
      &:hover,
      &:active,
      &.active {
        background-color: white;
      }
    }
    a.btn-dropdown {
      background-color: white;
      color: $color-grey-900;
      mat-icon {
        width: 1rem;
        height: 1rem;
      }
      &:hover,
      &:active,
      &.active {
        background-color: $color-primary-600;
      }
      &.bkg-warning-accent {
        background-color: $color-warning-accent;
        color: white;
      }
      &.bkg-success {
        background-color: $color-success;
        color: white;
      }
      &.bkg-primary-500 {
        background-color: $color-primary-500;
        color: white;
      }
    }
  }
  nav {
    padding-left: 6rem;
    padding-right: 4rem;
    margin-top: -0.6rem;
    padding-bottom: 1.5rem;
    a {
      font-size: 1.1rem;
      font-weight: 400;
      color: $color-primary-600;
      margin-right: 2rem;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        opacity: 0;
        width: 100%;
        height: 1.5px;
        left: 0;
        top: 170%;
        background-color: $color-primary-600;
        transition: all 0.3s ease-out;
      }
      &:hover,
      &:active,
      &.active {
        color: $color-primary-600;
        &::after {
          opacity: 1;
        }
      }
      &.disabled {
        opacity: 0.4;
      }
    }
  }
}
.page-wrapper.level-1 .toolbar {
  background-color: white;
  color: $color-primary-900;
  header .parent-link {
    display: none;
  }
  header .toolbar-title .title.no-tabs {
    .title-text,
    input {
      font-size: 2.4rem;
    }
  }
  header .toolbar-actions {
    a {
      color: $color-primary-900;
      &:hover,
      &:active,
      &.active {
        color: $color-primary-600;
      }
    }
    a.btn {
      color: $color-primary-900;
      &:hover,
      &:active,
      &.active {
        background-color: white;
      }
    }
  }
  nav {
    padding-left: 4rem;
  }
}
.narrow .page-wrapper .toolbar {
  background-color: $color-primary-900;
  color: white;
}
.narrow .page-wrapper.level-1 .toolbar {
  header .open-menu {
    display: flex;
  }
  header .toolbar-actions {
    a {
      color: $color-primary-600;
      &:hover,
      &:active,
      &.active {
        color: $color-primary-600;
      }
    }
  }
  nav {
    padding-left: 6rem;
  }
}
.wide .page-wrapper.level-1 .toolbar {
  margin-bottom: 0.4rem;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 0.4rem;
    background-image: linear-gradient(to bottom, $color-grey-200, white);
  }
  header {
    padding-left: 2.85rem;
    padding-right: 4rem;
  }
  nav {
    a {
      color: $color-grey-800;
      &::after {
        background-color: $color-primary-900;
      }
      &:hover,
      &:active,
      &.active {
        color: $color-primary-900;
      }
    }
  }
}

.split-page-layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
  overflow: auto;
  height: 100%;
  .page-item {
    display: flex;
    flex-direction: column;
    flex: 1 0 50%;
    position: relative;
    &.dark {
      background-color: $color-grey-50;
      .panel-sub-header {
        background-color: white !important;
      }
      .item-dummies,
      .item-dummies > div {
        background-color: $color-grey-50 !important;
      }
    }
    &.border {
      background-color: white;
      &::after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 0.4rem;
        height: 100%;
        background-image: linear-gradient(to right, $color-grey-200, $color-grey-50);
        z-index: 11;
      }
    }
  }
}
