// Base typography defined on _theming.scss

.mat-typography {
  mat-icon {
    line-height: 0 !important; // Angular 14 fix
    width: 1.6rem;
    height: 1.6rem;
    &.icon-xs {
      width: 0.8rem;
      height: 0.8rem;
    }
    &.icon-sm {
      width: 1rem;
      height: 1rem;
    }
    &.icon-md {
      width: 1.2rem;
      height: 1.2rem;
    }
    &.icon-lg {
      width: 2rem;
      height: 2rem;
    }
    &.icon-xl {
      width: 4rem;
      height: 4rem;
    }
    &.icon-xxl {
      width: 8rem;
      height: 8rem;
    }
  }
}

.mat-tooltip {
  white-space: pre-line;
  word-wrap: break-word;
  border: 1.5px solid $color-primary-900;
  background-color: $color-grey-50;
  color: $color-primary-900 !important;
  padding: 1rem;
}

.headline1 {
  font-size: 2.3rem;
  font-weight: 700;
  line-height: 3.2rem;
}
.headline2 {
  font-size: 2rem;
  font-weight: 600;
  line-height: 3.2rem;
}
.caption2 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2rem;
}
.caption3 {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.3rem;
}
.caption4 {
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 2rem;
}
.caption5 {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
}
.nav {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.7rem;
}
.label {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.7rem;
}
.tag {
  font-size: 1.1rem;
  font-weight: 600;
  line-height: 1.1rem;
}
.bold-label {
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 2rem;
}

.file-icon {
  min-width: 4rem;
  width: 4rem;
  height: 4rem;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-grey-600;
  color: white;
  border-radius: 3px;
  ::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    border-top: 0.8rem solid white;
    border-left: 0.8rem solid $color-grey-800;
    width: 0;
  }
  &.file-no-fill {
    background-color: transparent;
    border: 1.5px solid $color-grey-200;
    ::before {
      top: -1.5px;
      right: -1.5px;
      border-left-color: $color-grey-200;
    }
    ::after {
      content: "";
      position: absolute;
      top: -1.5px;
      right: -1.5px;
      width: 0.8rem;
      height: 0.8rem;
      border-top: 1.5px solid white;
      border-right: 1.5px solid white;
    }
  }
  &.file-doc,
  &.file-docx {
    background-color: $color-doc;
    ::before {
      border-left: 0.8rem solid $color-doc-dark;
    }
  }
  &.file-xls,
  &.file-xlsx {
    background-color: $color-xls;
    ::before {
      border-left: 0.8rem solid $color-xls-dark;
    }
  }
  &.file-ppt,
  &.file-pptx {
    background-color: $color-ppt;
    ::before {
      border-left: 0.8rem solid $color-ppt-dark;
    }
  }
  &.file-pdf {
    background-color: $color-pdf;
    ::before {
      border-left: 0.8rem solid $color-pdf-dark;
    }
  }
  &.file-icon-large {
    width: 5rem;
    height: 5rem;
  }
}
.text {
  line-height: 1.4;
  p,
  div {
    margin-bottom: 1rem;
    font-size: 1.3rem;
  }
  .highlight,
  .highlight > * {
    background-color: $color-highlight;
  }
}
hr.light {
  border: 0;
  height: 1px;
  border-top: 1px solid $color-grey-200;
}

.content-text {
  font-size: small;
}
