.search-wrapper {
  flex-shrink: 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  height: 4rem;
  margin-bottom: 4px;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 4px;
    background-image: linear-gradient(to bottom, $color-grey-200, $color-grey-50);
  }
  .search-keywords {
    padding-left: 1rem;
    display: flex;
    align-items: center;
    background-color: transparent;
    width: 31rem;
    &:hover,
    &:active,
    &.active,
    &:focus-within {
      background-color: white;
    }
    input {
      margin: 0 0.5rem;
      border: none;
      background-color: transparent;
      outline: none;
      width: 25.5rem;
    }
    .clear-search {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 1.4rem;
      height: 1.4rem;
      background-color: $color-grey-600;
      border-radius: 9999px;
      color: white;
      &:hover,
      &:active,
      &.active {
        background-color: $color-grey-200;
      }
    }
  }
  .search-options {
    display: flex;
    align-items: center;
    mat-icon {
      margin-right: 0.5rem;
    }
    white-space: nowrap;
    overflow-x: auto;
    .search-filters {
      display: flex;
      align-items: center;
    }
  }
}

.narrow .search-wrapper {
  .search-keywords {
    width: 26rem;
    input {
      width: 20.5rem;
    }
  }
}

.divider {
  background-color: $color-grey-200;
  &.divider-vertical {
    margin: 0 1rem;
    width: 1px;
    height: 2.5rem;
  }
  &.divider-horizontal {
    width: 100%;
    height: 1px;
  }
}

.wide {
  .no-selection {
    .search-wrapper {
      .search-keywords {
        width: 31rem;
        input {
          width: 25.5rem;
        }
      }
    }
  }
}
