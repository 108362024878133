.cdk-overlay-dark-backdrop {
  background: rgba($color-black, 0.32);
}

mat-dialog-container.mat-dialog-container {
  padding-top: 1.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
.custom-dialog-container {
  mat-dialog-container.mat-dialog-container {
    padding: 0;
  }
}
.mat-typography {
  .mat-dialog-title {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 0 3rem;
    &::after {
      content: "";
      position: absolute;
      top: 100%;
      left: -3rem;
      right: -3rem;
      height: 1px;
      background-color: $color-grey-200;
    }
    &.error-header {
      color: $color-error;
    }
    .tabs {
      display: flex;
      flex: 1 1 100%;
      padding: 0;
      border: 0;
      background-color: transparent;
      a {
        padding-top: 0.6rem;
      }
    }
  }
  .mat-dialog-content {
    &.fit-content {
      margin: -2rem -5rem -1.5rem -5rem;
    }
    &.fit-lib-search {
      margin-top: -2.9rem;
      margin-bottom: -1.5rem;
      padding: 0;
      margin-left: -3rem;
      margin-right: -3rem;
      lib-search {
        height: 56.5rem;
        .panel-search.layout-grid {
          .collection-items .grid-end {
            grid-column-start: 1;
            grid-column-end: 4;
          }
        }
      }
    }
    // .mat-form-field-flex {
    //   background-color: transparent !important;
    // }
    .mat-dialog-content-container {
      max-height: 65vh;
      .content {
        height: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column;
        .grid.grid-2 {
          grid-template-columns: auto 1fr;
          grid-gap: 1.5rem;
          grid-auto-rows: auto;
        }
      }
    }
    .mat-tree {
      min-height: 4rem;
    }
  }
  .mat-dialog-actions {
    margin-top: 1.5rem;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    min-height: 3rem;
    &.space-between {
      justify-content: space-between;
    }
    &.warning {
      justify-content: space-between;
      mat-icon {
        color: $color-warning;
      }
    }
    &::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -3rem;
      right: -3rem;
      height: 1px;
      background-color: $color-grey-200;
    }
    > a {
      margin-right: 1rem;
    }
  }
}
