.light-bottom-shadow {
  -webkit-box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 6px -3px rgba(0, 0, 0, 0.1);
}

.light-top-shadow {
  -webkit-box-shadow: 0px -3px 3px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px -3px 3px 0px rgba(0, 0, 0, 0.1);
}

.light-bottom-right-shadow {
  -webkit-box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 6px 0 rgba(0, 0, 0, 0.1);
}
