@mixin generateColors($name, $palette, $prefix: "color") {
  @each $key, $value in $palette {
    @if (type-of($value) == color) {
      .#{$prefix}-#{$name}-#{$key} {
        color: $value;
      }
    }
  }
}
@mixin generateBackgrounds($name, $palette, $prefix: "bkg") {
  @each $key, $value in $palette {
    @if (type-of($value) == color) {
      .#{$prefix}-#{$name}-#{$key} {
        background-color: $value !important;
      }
    }
  }
}

// Colors
@include generateColors("primary", $navy-blue-palette);
@include generateColors("grey", $grey-palette);
@include generateColors("info", $info-palette);
@include generateColors("success", $success-palette);
@include generateColors("warning", $warning-palette);
@include generateColors("error", $error-palette);

.color-success {
  color: $color-success;
}
.color-warning {
  color: $color-warning;
}
.color-error {
  color: $color-error !important;
}
.color-highlight {
  em {
    background-color: $color-highlight;
    font-style: normal;
  }
}
.color-white {
  color: $color-white;
}
.color-transparent {
  color: transparent;
}

// Backgrounds
@include generateBackgrounds("primary", $navy-blue-palette);
@include generateBackgrounds("grey", $grey-palette);
@include generateBackgrounds("info", $info-palette);
@include generateBackgrounds("success", $success-palette);
@include generateBackgrounds("warning", $warning-palette);
@include generateBackgrounds("error", $error-palette);

.bkg-success {
  background-color: $color-success;
}
.bkg-success-opacity {
  background-color: rgba($color-success, 0.1);
}
.bkg-warn {
  background-color: $color-warning;
}
.bkg-error {
  background-color: $color-error;
}
.bkg-error-opacity {
  background-color: rgba($color-error, 0.1);
}
.bkg-white {
  background-color: $color-white;
}
.bkg-transparent {
  background-color: transparent;
}
.bkg-highlight {
  background-color: rgba($color-highlight, 0.1);
}
.bkg-blink {
  animation: blink 2s infinite linear forwards;
}
.bkg-warning-accent {
  background-color: $color-warning-accent;
}

// Borders
.bt-1-light {
  border-top: 1px solid $color-grey-200;
}

// Spinners
.white-spinner {
  .mat-progress-spinner circle,
  .mat-spinner circle {
    stroke: $color-white;
  }
}

// ***********************
// Filters: https://codepen.io/sosuke/pen/Pjoqqp

// ***********************
// blue - 7 combinations
.color-blue-1 {
  background-color: #0c3da4;
  &::after {
    // color: #175abd;
    filter: invert(21%) sepia(98%) saturate(5866%) hue-rotate(212deg) brightness(87%) contrast(82%);
  }
}
.color-blue-2 {
  background-color: #175abd;
  &::after {
    // color: #5f91c5
    filter: invert(65%) sepia(11%) saturate(2089%) hue-rotate(173deg) brightness(84%) contrast(80%);
  }
}
.color-blue-3 {
  background-color: #175abd;
  &::after {
    // color: #0c3da4;
    filter: invert(16%) sepia(85%) saturate(2463%) hue-rotate(210deg) brightness(101%) contrast(94%);
  }
}
.color-blue-4 {
  background-color: #0c3da4;
  &::after {
    // color: #189c59;
    filter: invert(45%) sepia(49%) saturate(692%) hue-rotate(97deg) brightness(96%) contrast(90%);
  }
}
.color-blue-5 {
  background-color: #0c3da4;
  &::after {
    // color: #c43d1b;
    filter: invert(24%) sepia(96%) saturate(1612%) hue-rotate(352deg) brightness(94%) contrast(89%);
  }
}
.color-blue-6 {
  background-color: #175abd;
  &::after {
    // color: #3a0d49;
    filter: invert(13%) sepia(23%) saturate(7090%) hue-rotate(270deg) brightness(70%) contrast(103%);
  }
}
.color-blue-7 {
  background-color: #175abd;
  &::after {
    // color: #189c59;
    filter: invert(45%) sepia(49%) saturate(692%) hue-rotate(97deg) brightness(96%) contrast(90%);
  }
}

// ***********************
// green - 6 combinations
.color-green-1 {
  background-color: #085c28;
  &::after {
    // color: #107c41;
    filter: invert(20%) sepia(87%) saturate(2934%) hue-rotate(145deg) brightness(89%) contrast(87%);
  }
}
.color-green-2 {
  background-color: #107c41;
  &::after {
    // color: #085c28;
    filter: invert(23%) sepia(31%) saturate(1767%) hue-rotate(99deg) brightness(100%) contrast(94%);
  }
}
.color-green-3 {
  background-color: #085c28;
  &::after {
    // color: #c43d1b;
    filter: invert(24%) sepia(96%) saturate(1612%) hue-rotate(352deg) brightness(94%) contrast(89%);
  }
}
.color-green-4 {
  background-color: #107c41;
  &::after {
    // color: #175abd;
    filter: invert(21%) sepia(98%) saturate(5866%) hue-rotate(212deg) brightness(87%) contrast(82%);
  }
}
.color-green-5 {
  background-color: #107c41;
  &::after {
    // color: #3a0d49;
    filter: invert(13%) sepia(23%) saturate(7090%) hue-rotate(270deg) brightness(70%) contrast(103%);
  }
}
.color-green-6 {
  background-color: #189c59;
  &::after {
    // color: #8824a2;
    filter: invert(20%) sepia(75%) saturate(2187%) hue-rotate(271deg) brightness(95%) contrast(102%);
  }
}

// ***********************
// purple - 7 combinations
.color-purple-1 {
  background-color: #3a0d49;
  &::after {
    // color: #66197d;
    filter: invert(8%) sepia(68%) saturate(4816%) hue-rotate(281deg) brightness(122%) contrast(91%);
  }
}
.color-purple-2 {
  background-color: #66197d;
  &::after {
    // color: #8824a2;
    filter: invert(20%) sepia(75%) saturate(2187%) hue-rotate(271deg) brightness(95%) contrast(102%);
  }
}
.color-purple-3 {
  background-color: #66197d;
  &::after {
    // color: #3a0d49;
    filter: invert(13%) sepia(23%) saturate(7090%) hue-rotate(270deg) brightness(70%) contrast(103%);
  }
}
.color-purple-4 {
  background-color: #3a0d49;
  &::after {
    // color: #175abd;
    filter: invert(21%) sepia(98%) saturate(5866%) hue-rotate(212deg) brightness(87%) contrast(82%);
  }
}
.color-purple-5 {
  background-color: #3a0d49;
  &::after {
    // color: #107c41;
    filter: invert(20%) sepia(87%) saturate(2934%) hue-rotate(145deg) brightness(89%) contrast(87%);
  }
}
.color-purple-6 {
  background-color: #66197d;
  &::after {
    // color: #ad0e00;
    filter: invert(15%) sepia(94%) saturate(2839%) hue-rotate(357deg) brightness(89%) contrast(114%);
  }
}
.color-purple-7 {
  background-color: #66197d;
  &::after {
    // color: #189c59;
    filter: invert(45%) sepia(49%) saturate(692%) hue-rotate(97deg) brightness(96%) contrast(90%);
  }
}

// ***********************
// red - 6 combinations
.color-red-1 {
  background-color: #c43d1b;
  &::after {
    // color: #f67c3d;
    filter: invert(73%) sepia(38%) saturate(5991%) hue-rotate(335deg) brightness(100%) contrast(93%);
  }
}
.color-red-2 {
  background-color: #c43d1b;
  &::after {
    // color: #ad0e00;
    filter: invert(15%) sepia(94%) saturate(2839%) hue-rotate(357deg) brightness(89%) contrast(114%);
  }
}
.color-red-3 {
  background-color: #ad0e00;
  &::after {
    // color: #0c3da4;
    filter: invert(16%) sepia(85%) saturate(2463%) hue-rotate(210deg) brightness(101%) contrast(94%);
  }
}
.color-red-4 {
  background-color: #ad0e00;
  &::after {
    // color: #5f91c5
    filter: invert(65%) sepia(11%) saturate(2089%) hue-rotate(173deg) brightness(84%) contrast(80%);
  }
}
.color-red-5 {
  background-color: #c43d1b;
  &::after {
    // color: #107c41;
    filter: invert(20%) sepia(87%) saturate(2934%) hue-rotate(145deg) brightness(89%) contrast(87%);
  }
}
.color-red-6 {
  background-color: #f67c3d;
  &::after {
    // color: #3a0d49;
    filter: invert(13%) sepia(23%) saturate(7090%) hue-rotate(270deg) brightness(70%) contrast(103%);
  }
}

// ***********************
// grey - 2 combinations
.color-grey-1 {
  background-color: #222d2b;
  &::after {
    // color: #364644;
    filter: invert(22%) sepia(28%) saturate(317%) hue-rotate(123deg) brightness(94%) contrast(85%);
  }
}
.color-grey-2 {
  background-color: #364644;
  &::after {
    // color: #4c615d;
    filter: invert(36%) sepia(8%) saturate(868%) hue-rotate(119deg) brightness(93%) contrast(86%);
  }
}

// ****************
// patterns
$variations: (1, 2, 3, 4, 5, 6, 7, 8, 9, 10);
.pattern {
  color: transparent;
  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    color: black;
    content: "";
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  @each $variation in $variations {
    &.pattern-#{$variation}::after {
      background-image: url(/assets/patterns/#{$variation}.svg);
    }
  }
}
