@use "sass:math";

$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50);
$sides: (top, bottom, left, right);
@each $space in $spaceamounts {
  @each $side in $sides {
    .m#{str-slice($side, 0, 1)}-#{math.div($space, 5)} {
      margin-#{$side}: #{$space}px !important;
    }
    .p#{str-slice($side, 0, 1)}-#{math.div($space, 5)} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}
@each $space in $spaceamounts {
  .m-#{math.div($space, 5)} {
    margin: #{$space}px !important;
  }
  .mh-#{math.div($space, 5)} {
    margin-left: #{$space}px !important;
    margin-right: #{$space}px !important;
  }
  .mv-#{math.div($space, 5)} {
    margin-top: #{$space}px !important;
    margin-bottom: #{$space}px !important;
  }
  .p-#{math.div($space, 5)} {
    padding: #{$space}px !important;
  }
  .ph-#{math.div($space, 5)} {
    padding-left: #{$space}px !important;
    padding-right: #{$space}px !important;
  }
  .pv-#{math.div($space, 5)} {
    padding-top: #{$space}px !important;
    padding-bottom: #{$space}px !important;
  }
}

.lh-2 {
  line-height: 2;
}
