@keyframes fadeOut {
  0% {
    opacity: 1;
    display: flex;
  }
  60% {
    opacity: 1;
    display: flex;
  }
  100% {
    opacity: 0;
    display: none;
    z-index: -1;
  }
}

@keyframes blink {
  0% {
    background-color: $color-grey-200;
  }
  50% {
    background-color: $color-grey-50;
  }
  100% {
    background-color: $color-grey-200;
  }
}
