.tabs {
  flex-shrink: 0;
  background-color: $color-grey-50;
  padding: 0 2rem;
  border-bottom: 1px solid $color-grey-200;
  a {
    display: inline-block;
    padding: 1.2rem 0;
    margin-right: 2rem;
    border-bottom: 2px solid transparent;
    color: $color-grey-800;
    &:hover,
    &:active,
    &.active {
      color: $color-primary-900;
      border-bottom: 2px solid $color-primary-900;
    }
  }
  &.dialog-tabs {
    background-color: transparent;
    padding: 0;
    border-bottom: none;
  }
}
