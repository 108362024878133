@use "@angular/material" as mat;

// **************************
// Level 2 - search

.panel-search {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  .panel-collection {
    flex-grow: 1;
    position: relative;
    width: 100%;
    height: 100%;
    .collection {
      width: 100%;
      height: 100%;
      padding: 2rem;
    }
    .collection-dummies {
      position: absolute;
      overflow: hidden;
      z-index: 10;
    }
    .collection-items {
      overflow: auto;
    }
    hr {
      border: 0;
      height: 1px;
      border-top: 1px solid $color-grey-200;
      margin: 2rem 0;
    }
  }
  .panel-item {
    flex-shrink: 0;
    width: 0;
    overflow: hidden;
    transition: width 0.3s ease-out;
  }
}

.panel-tools,
.panel-content-details,
.panel,
.panel-collection-content,
.mat-dialog-content {
  .item-dummies {
    position: absolute;
    overflow: hidden;
    z-index: 10;
    width: 100%;
    .repeat {
      display: grid;
      grid-gap: 1rem;
      align-content: start;
      height: 100%;
    }
  }

  &.loaded {
    .item-dummies {
      animation: fadeOut 0.5s linear forwards;
    }
  }
}

.panel-dialog .item-sub-header {
  min-height: 3.5rem;
  background-color: $color-grey-50;
  color: $color-primary-900;
  display: flex;
  flex-direction: row;
  padding: 0 2rem 0 1rem;
}

.panel-search.item-selected {
  .panel-item {
    width: 46rem;
    overflow: auto;
  }
}

.panel-search.layout-grid .panel-collection {
  .collection-dummies,
  .collection-items {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    align-content: start;
    background-color: $color-grey-50;
  }
  // fix a bug with bottom padding not applied, force an additional pixel
  .collection-items .grid-end {
    height: 1px;
    grid-column-start: 1;
    grid-column-end: 6;
  }
}

.panel-search.layout-grid.item-selected {
  .collection-dummies,
  .collection-items {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .collection-items .grid-end {
    grid-column-start: 1;
    grid-column-end: 4;
  }
}

.panel-search.layout-grid.grid-3 {
  .collection-dummies,
  .collection-items {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.panel-search.layout-list .panel-collection {
  .collection-dummies,
  .collection-items {
    background-color: $color-grey-50;
    .card {
      margin-bottom: 0.5rem;
    }
  }
}

.panel-search.layout-list.presentation-card {
  .panel-collection .collection-items .card {
    .card-value {
      width: 82rem;
      padding-left: 2rem;
    }
    .card-list-footer-row {
      display: flex;
      flex-direction: row;
    }
  }
  &.item-selected {
    .panel-collection .collection-items .card .card-value {
      width: 0;
    }
  }
}

.wide-panel-search {
  .panel-search.layout-list.presentation-card {
    &.item-selected {
      .panel-item {
        width: 82rem;
      }
    }
  }
}

.panel-search.layout-grid.presentation-card .panel-collection {
  .collection-items .card {
    .item-subtitle {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .item-title {
      height: 5.4rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 3;
    }
  }
}

.panel-search.layout-list.presentation-highlights .panel-collection {
  .collection-dummies,
  .collection-items {
    background-color: white;
  }
}

.panel-search.loaded {
  .collection-dummies {
    animation: fadeOut 0.5s linear forwards;
  }
}

// **************************
// Level 2 - panel details

.panel-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.panel-details .panel-collection {
  flex-shrink: 0;
  position: relative;
  width: 34rem;
  height: 100%;
  display: flex;
  transition: width 0.3s ease-out;
  background-color: $color-grey-50;
  &::after {
    content: "";
    position: absolute;
    left: calc(100% - 0.4rem);
    top: 0;
    width: 0.4rem;
    height: 100%;
    background-image: linear-gradient(to right, $color-grey-50, $color-grey-200);
    z-index: 11;
  }
  .panel-search {
    overflow-x: hidden;
  }
}

.panel-details .panel-collection .panel-collection-content {
  transition: width 0.3s ease-out;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.panel-details .panel-collection .panel-toggle {
  color: $color-grey-800;
  transition: width 0.3s ease-out;
  width: 0;
  height: 100%;
  overflow: hidden;
  &:hover,
  &:active,
  &.active {
    color: $color-primary-600;
  }
}

.panel-details .panel-collection .panel-toggle .panel-toggle-content {
  display: flex;
  padding-left: 1.75rem;
  padding-top: 2rem;
  font-size: 1.3rem;
  font-weight: 400;
  writing-mode: vertical-rl;
  mat-icon {
    transform: scaleX(-1);
    margin-bottom: 0.5rem;
    align-self: flex-end;
  }
}

.panel-details .panel-collection .panel-toggle .panel-toggle-icon {
  .panel-toggle-icon-left {
    display: flex;
  }
  .panel-toggle-icon-right {
    display: none;
  }
  position: absolute;
  border-radius: 999px;
  top: 2.75rem;
  right: -1.25rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  mat-icon {
    z-index: 14;
  }
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 4px 0 $color-grey-800;
    background-color: white;
    border-radius: 9999px;
    z-index: 12;
  }
  &::after {
    content: "";
    position: absolute;
    top: -0.3rem;
    left: 50%;
    width: calc(50% + 0.6rem);
    height: calc(100% + 0.6rem);
    background-color: white;
    z-index: 13;
  }
}

.panel-details .panel-collection .panel-collection-content {
  .search-wrapper .search-keywords {
    width: 18rem;
    input {
      width: 12.5rem;
    }
  }
  .content {
    flex-grow: 1;
    height: auto;
    position: relative;
    width: 34rem;
    display: flex;
    transition: width 0.3s ease-out;
    background-color: $color-grey-50;
    &.loaded {
      .item-dummies {
        animation: fadeOut 0.5s linear forwards;
      }
    }
    .card.disabled {
      opacity: 1;
      background-color: white;
    }
  }
}

// Collapsed

.panel-details .panel-collection.panel-collapsed {
  width: 5rem;
  .panel-collection-content {
    width: 0;
    overflow: hidden;
  }
  .panel-toggle {
    width: 100%;
    .panel-toggle-icon {
      .panel-toggle-icon-left {
        display: none;
      }
      .panel-toggle-icon-right {
        display: flex;
      }
    }
  }
}

// Item

.panel-details .panel-item {
  flex-grow: 1;
  width: auto;
  height: 100%;
  display: flex;
  .panel-content {
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    .mask-contents {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
  .panel-properties {
    flex-shrink: 0;
  }
}

// Center Panel

.panel {
  &.panel-tools {
    display: flex;
    height: 100%;
    border-left: 1px solid $color-grey-200;
    background-color: white;
  }
  &.panel-main {
    display: flex;
    flex-grow: 1;
    position: relative;
  }
  &.loaded {
    .item-dummies-container {
      .item-dummies {
        animation: fadeOut 0.5s linear forwards;
      }
    }
  }
  &.overlayed {
    position: absolute;
    right: 0;
    width: 46rem;
    overflow: hidden;
    z-index: 2;
    height: calc(100% - 5rem);
    transition: all 0.3s ease-out;
  }
  &.minimize {
    width: 6rem;
    transition: all 0.3s ease-out;
  }
  &.panel-hide {
    width: 0;
    transition: all 0.3s ease-out;
  }
  .panel-tools-bar {
    width: 6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    a {
      border-radius: 50%;
      width: 4rem;
      height: 4rem;
      margin-bottom: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      color: $color-primary-900;
      &:hover {
        color: $color-primary-900;
        background-color: $color-grey-50;
      }
      &:active,
      &.active {
        color: $color-primary-600;
        background-color: $color-grey-50;
      }
      .badge {
        position: absolute;
        border-radius: 2px;
        top: 0;
        right: 0;
        padding: 0.2rem 0.4rem 0.1rem 0.4rem;
        background-color: $color-primary-600;
        color: white;
      }
      mat-icon {
        width: 2rem;
        height: 2rem;
      }
      &.close-tools mat-icon {
        width: 1.2rem;
        height: 1.2rem;
      }
    }
    &.hide-tools {
      width: 0;
      * {
        display: none;
      }
    }
  }
  &.panel-expanded {
    .panel-container {
      width: 40rem;
      flex-grow: 1;
      padding: 2rem 2rem 0 2rem;
      transition: all 0.3s ease-out;
      display: flex;
      flex-direction: column;
      height: 100%;
      &.main {
        padding: 2rem 4rem 0 4rem;
      }
    }
  }
  .panel-container {
    width: 0;
    transition: all 0.3s ease-out;
    display: flex;
    flex-direction: column;
    position: relative;
    &.loaded {
      .item-dummies-container {
        .item-dummies {
          animation: fadeOut 0.5s linear forwards;
        }
      }
    }
    .panel-header {
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      &.underline {
        padding-bottom: 1.5rem;
        border-bottom: 1px solid $color-grey-600;
      }
      &.reduced {
        max-width: 73rem;
      }
      &.align-items-normal {
        align-items: normal;
      }
    }
    .panel-sub-header {
      margin-top: 2rem;
      min-height: 3.5rem;
      background-color: $color-grey-50;
      color: $color-primary-900;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 2rem 0 1rem;
      .btn-filter {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
    .panel-content {
      display: flex;
      flex-direction: column;
      overflow: auto;
      position: relative;
      flex-grow: 1;
      padding-top: 2rem;
      padding-bottom: 2rem;
      .grid.grid-2 {
        grid-template-columns: auto 1fr;
        grid-gap: 1.5rem;
        grid-auto-rows: auto;
      }
      .panel-dummies-container {
        height: 100%;
        position: relative;
      }
      &.loaded {
        .item-dummies {
          animation: fadeOut 0.5s linear forwards;
        }
      }
      .reduced {
        max-width: 73rem;
      }
    }
    .panel-footer {
      position: relative;
      height: 6rem;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .panel-footer::before {
      content: "";
      position: absolute;
      background-image: linear-gradient(to bottom, white, $color-grey-200);
      top: 0;
      left: -40px;
      right: -40px;
      height: 0.4rem;
    }
    .viewer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
      padding-top: 1rem;
      background-color: white;
      .pdf-paging {
        white-space: nowrap;
      }
      &.full-size {
        position: fixed;
        padding-top: 0;
        z-index: 999;
        .pdf-paging {
          padding-left: 2rem;
        }
      }
      .zoom {
        max-height: 100%;
      }
    }
    &.hide-panel-container {
      * {
        display: none;
      }
    }
  }
}

.panel-item .panel-container,
.split-page-layout .panel-container,
.panel-tools.panel-expanded {
  .panel-content.overlay-container {
    margin-left: -2rem;
    padding: 3rem 1rem 0 2rem;
  }
}

// Panel dialog

.panel-dialog {
  position: relative;
  margin-bottom: -2.4rem;
  height: 90vh;
  &.full-width {
    padding: 0;
    margin-left: -3rem;
    margin-right: -3rem;
    margin-bottom: -1.5rem;
  }
  .mat-dialog-content {
    display: flex;
    max-height: unset;
    height: 100%;
  }
  .mat-dialog-actions::before {
    right: 0rem !important;
  }
  .mat-dialog-actions {
    margin-top: 1px !important;
    display: flex;
    justify-content: flex-start !important;
  }
  .richtext-tool {
    overflow: hidden;
  }
  .dialog-close {
    width: 1.6rem !important;
    height: 1.6rem !important;
  }
  .panel-tools {
    height: 90vh;
    border: 0;
    position: relative;
    margin-right: -3rem;
    &::before {
      content: "";
      bottom: 0rem;
      position: absolute;
      top: -1.5rem;
      background-color: $color-grey-200;
      width: 1px;
    }
  }
}

.img-panel {
  .img-panel-container {
    flex-basis: 40%;
    padding: 10rem;

    .border-top {
      border-top: 1px solid $color-grey-200;
    }
    .circle-icon {
      height: 4rem;
      width: 4rem;
      border: 1.5px solid $color-primary-900;
      border-radius: 999px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    section {
      flex-grow: 1;
    }
  }
  .img-content {
    flex-basis: 60%;
    background-image: radial-gradient(circle farthest-side at top right, $color-error-200 0%, $color-primary-900 100%);
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    img {
      max-height: 100%;
      max-width: auto;
    }
  }
}

.value-container {
  position: relative;
  width: fit-content;
  .label {
    color: $color-grey-800;
  }
  .options {
    display: none;
  }
  .overlay {
    top: -0.5rem;
    left: -2rem;
    border: none;
    .edit {
      display: none;
    }
  }
  .value {
    z-index: 2;
    &:hover,
    &.active {
      cursor: pointer;
      .options {
        display: inline-block;
        position: absolute;
        z-index: 2;
        margin: 0;
        color: $color-grey-800;
        &:hover,
        &.active,
        &:active {
          color: $color-primary-600;
          cursor: pointer;
        }
        &.multiline {
          bottom: 0.5rem;
        }
      }
    }
  }
  &:hover,
  &.active,
  &:active {
    &:not(.no-action) {
      .label {
        color: $color-primary-600;
      }
    }
    .edit {
      display: inherit;
      padding-top: 0.6rem;
    }
  }
}

// ****************************
// empty collection list
.shadow {
  width: 8rem;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 4px 5px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
}

.collection-empty {
  .text {
    width: 20rem;
    text-align: center;
  }
}
